.homehero{
    width:100%;
    height:calc(100vh - 90px);
    background-image: url('../../assets/team.jpg');
    background-position: center  center;
    background-size: cover;
    background-attachment: fixed;
    z-index: 1;
    position:relative;
}

.homehero::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.822);
  z-index: -1;
}

.texts-container{
    width:700px;
    padding:10px;
    background-color: rgba(255, 255, 255, 0.3);
}

.divider{
    width:90%;
    height:2px;
    background-color: #fff;
}

.join-btn{
    width:200px;
}

.title{
    font-family: 'Playfair Display', serif;
}

@media screen and (max-width:670px){
    .texts-container{
        width:90%;
    }
}

@media screen and (max-width:500px){
    .title{
        font-size: 27px;
    }

    .title-2{
        font-size: 20px;
    }
}