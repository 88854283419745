.singleblog{
    width: 100%;
    margin-top: 90px;
}

.single-b-image{
    width:100%;
    height:50vh;
    overflow:hidden;
}

.single-b-image img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.single-b-description p::first-letter{
    font-weight: 800;
    font-size: 28px;
    margin-left: 10px;
    color:gray;
}