.article{
    width:100%;
    background-color: #F8F9FC;
}

.article-title{
    margin-top: -200px;
}

@media screen and (max-width:1121px){
.article-title {
        margin-top: -100px;
    }
}

@media screen and (max-width:822px){
    .article-title {
        margin-top:0;
    }
    
    .article-text{
        width:95%;
    }
}