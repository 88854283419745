.service-programs{
    width:100%;
    background-color: #F8F9FC;
}

.program-btn{
    width:200px;
}

.modal{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color: black;
    z-index: 1000;
}

@media screen and (max-width:480px){
    .service-btns{
        flex-direction: column;
    }
        .program-btn {
            width: 95%;
            margin-bottom: 10px;
        }

        .program-btn:first-child{
            margin-right: 0;
        }

        .program-btn:last-child{
            margin-left: 0;
        }
}