.service-video-wrapper{
    width:100%;
}

.service-v{
    width:50%;
    background-color: #F8F9FC;
}

.service-p{
    width:50%;
    background-color: #F8F9FC;
}

.service-p img, .service-v img{
    width:100%;
    height:30vh;
    object-fit: cover;
    overflow: hidden;
}

.upload-input, .upload-btn{
    width:100%;
}

@media screen and (max-width:1034px){
    .service-video-wrapper{
        width:100%;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .service-p {
        width: 95%;
    }

    .service-v {
        width: 95%;
    }
}