.single-blog{
    background-color: #F8F9FC;
}

.single-blog-left{
    width:40%;
}

.single-blog-left img{
    width:100%;
    height:35vh;
    object-fit:cover;
    overflow: hidden;
}

.single-blog-right {
    width: 60%;
}

.article-desc{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media screen and (max-width:1056px){
    .single-blog{
        width:95%;
        flex-direction: column;
    }

    .single-blog-left {
        width: 100%;
    }

    .single-blog-right {
        width: 100%;
        margin-top: 10px;
    }
}