@import url('https://fonts.googleapis.com/css2?family=Mea+Culpa&family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar{
    width:100%;
    font-family: 'Nunito Sans', sans-serif;
    position:fixed;
    top:0;
    z-index:999;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
}

.logo{
    flex:1;
}

.logo img{
    width:50px;
    height:50px;
    object-fit:cover;
}

.top-nav{
    height:37px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.486);
}

.bottom-nav{
    height:55px;
    padding:10px 0;
}

.nav-links{
    flex:3;
}

.nav-btns{
    flex:1;
}

.admin-btn{
    border-width: 1px;
}

.nav-links p{
    font-size: 19px;
}

.nav-blog{
    position: relative;
}

.blog-links{
    position: absolute;    
    width:200px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: none;
}

.blog-links-wrapper{
    margin-top: 5px;
    background-color: #F8F9FC;
}

.nav-blog:hover .blog-links{
    display: block;
}

.blog-links:hover{
    display: block;
}


.register-form{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: rgba(0, 0, 0, 0.747);
}

.form-wrapper{
    width:400px;
    background-color: #fff;
}

.form-inputs{
    width:100%;
}

.register-inputs, .register-student-btn {
    width: 100%;
}

.web-view{
    display: flex;
}

.mobile-menu{
    display: none;
}

.mobile-bg{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: rgba(0, 0, 0, 0.63);
    transform:translateY(-100vh);
    transition: all ease 1s;
}

.show-mobile-bg{
    transform: translateY(0);
}

.mobile-nav{
    position: fixed;
    top:0;
    right:0;
    width:300px;
    height: 100vh;
    background-color: #F8F9FC;    
    transform:translateX(600px);
    transition: all ease 1s;
    
}

.show-nav{
    transform: translateX(0);
}

@media screen and (max-width:1025px){
    .web-view {
            display: none;
        }
    
        .mobile-menu {
            display: flex;
        }
}

@media screen and (max-width:630px){
    .top-bar-text{
        font-size: 16px;
    }
}

@media screen and (max-width:500px){
.top-bar-text {
        font-size: 12px;
    }
}

@media screen and (max-width:370px) {
    .top-bar-text {
        font-size: 10px;
    }
}