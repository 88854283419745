.login {
    width: 100%;
    position: absolute;
    height:calc(100vh - 90px);
    margin-top: 90px;
    background-image: url('../../assets/team.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
}

.login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.788);
    z-index: -2;
}

.login-wrapper{
    width:500px;
    background-color: #fff;
}

.login-inputs, .login-btn{
    width:100%;
}

@media screen and (max-width:520px){
    .login-wrapper{
        width:95%;
    }
}