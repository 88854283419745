.ex-official{
    width:100%;
}

.leader-container{
    width:30%;
    height:40vh;
    overflow:hidden;
    box-shadow: -3px 1px 14px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: -3px 1px 14px -3px rgba(0,0,0,0.75);
-moz-box-shadow: -3px 1px 14px -3px rgba(0,0,0,0.75);
}

.leader-container:hover.leader-container::before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.507);
}

.leader-container img{
    width:100%;
    height:75%;
    object-fit: cover;
}

.name-container{
    /* position: absolute; */
    left:0;
    bottom:10%;
    width:100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

@media screen and (max-width:1024px) {
    .leader-container {
        width: 70%;
    }
}

@media screen and (max-width:800px) {
    .leader-container {
        width: 95%;
    }
}