.book-container{
    position: relative;
    width: 30%;
    height: 40vh;
    overflow: hidden;
}

.book-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.363);
}

.book-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download{
    position: absolute;
    top:10px;
    right:10px;
    width:50px;
    height:50px;
    background-color: #F8F9FC;
}

.download:hover{
    background-color: #c9c9c9;
}

.download-icon{
    width:60%;
    height:60%;
}

@media screen and (max-width:770px) {
    .book-container {
        width: 95%;
    }
}