.single-video-container {
    position: relative;
    width: 30%;
    height: 40vh;
    overflow: hidden;
}

.single-video-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.single-video-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
}