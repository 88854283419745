.eventsdata{
    width:100%;
}

.event-container{
    position: relative;
    width:30%;
    height:40vh;
    overflow: hidden;
}

.event-container:hover.event-container::before{
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.616);
}

.event-container img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.event-title{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:30px;
}

@media screen and (max-width:1024px) {
    .event-container{
        width: 70%;
    }
}

@media screen and (max-width:800px) {
    .event-container {
        width: 95%;
    }
}