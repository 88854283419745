.testimonials{
    width:100%;
}

.testimonial-cont{
    position: relative;
    height:46vh;
    width:30%;
    overflow:hidden;
    border-width:1px;
}

.animation-tab{
    width:101%;
    height:30px;
    position: absolute;
    bottom:0;
    transform:translateY(30px);
    transition:all ease .4s;
}

.testimonial-cont:hover .animation-tab{
    transform: translateY(0px);
}

.testimonial-cont img{
    width:100px;
    height:100px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width:1050px){
.testimonials-wrapper{
    justify-content: center;
}

}

@media screen and (max-width:770px){
    .testimonial-cont {
            width: 95%;
            height:auto;
        }
}