.avatar-container{
    width:50px;
    height:50px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-container img{
    width:100%;
    height:100%;
    object-fit: cover;
}