.square {
    height: 60px;
    width: 60px;
    background-color: #4e6bff;
    opacity: 0.7;
    position: absolute;
    top: -60px;
    height: -60px;
    z-index: -1;
    animation: square 13s linear alternate infinite;
}

.circle {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: green;
    opacity: 0.7;
    position: absolute;
    top: -60px;
    height: -60px;
    z-index: 21;
    animation: circle 13s linear alternate infinite;
}

@keyframes square {
    to {
        transform: translate(40vw, 90vh);
    }
}

@keyframes circle {
    to {
        transform: translate(-40vw, 70vh);
    }
}